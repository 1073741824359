import { FuseNavigationItem } from '../../../../@fuse/components/navigation';

export const modulesItems: FuseNavigationItem = {
    id: 'project',
    title: 'menu.project.title',
    type: 'group',
    icon: 'heroicons_outline:sparkles',
    children: [
        {
            id: 'activities.modules',
            title: 'module.provider.menu',
            type: 'basic',
            icon: 'heroicons_outline:collection',
            link: '/provider/list'
        },
        {
            id: 'activities.modules',
            title: 'module.customer.menu',
            type: 'basic',
            icon: 'heroicons_outline:user-group',
            link: '/customer/list'
        },
        {
            id: 'activities.modules',
            title: 'module.project.menu',
            type: 'basic',
            icon: 'heroicons_outline:document-report',
            link: '/project/list'
        },
        {
            id: 'activities.modules',
            title: 'module.inventory.menu',
            type: 'basic',
            icon: 'heroicons_outline:archive',
            link: '/inventory/list'
        },
        {
            id: 'activities.modules',
            title: 'module.budget.menu',
            type: 'basic',
            icon: 'heroicons_outline:calculator',
            link: '/budget/list'
        },
        {
            id: 'activities.modules',
            title: 'module.bill.menu',
            type: 'basic',
            icon: 'heroicons_outline:cash',
            link: '/bill/list'
        },
        {
            id: 'activities.modules',
            title: 'module.bill-received.menu',
            type: 'basic',
            icon: 'heroicons_outline:inbox-in',
            link: '/bill-received/list'
        }
    ]
};
